<template>
  <FormKit type="form" :id="formId" :actions="false" @submit="handleSubmit">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-[80%_1fr]">
      <FormKit
        v-model="voucherCode"
        type="text"
        name="vouchercode"
        :label="t('labels.subscriptionNumber')"
        :floating-label="true"
        validation="required"
        :validation-messages="{
          required: t('messages.error'),
        }"
        :validation-label="t('labels.subscriptionNumber')"
      />

      <KButton
        :text="t('btn.submit')"
        :type="BUTTON_TYPE.SUBMIT"
        :theme="BUTTON_THEME.PRIMARY"
        :loading="pending"
        class="rounded-lg md:!h-14"
      />
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import { BUTTON_THEME, BUTTON_TYPE } from '@kh-ui/constants/button'
import { MESSAGE_SEVERITY, MESSAGE_TYPE } from '@base/constants/message'
import { reset } from '@formkit/core'

const { t } = useI18n()
const { add: addMessage } = useMessage()
const formId = useId()
const voucherCode = ref('')
const { pending, checkSubscription } = await useSubscriptionInfo()

async function handleSubmit({ vouchercode }: { vouchercode: string }) {
  try {
    await checkSubscription(vouchercode)
  } catch (error) {
    addMessage({
      message: t('messages.error'),
      type: MESSAGE_TYPE.TOAST,
      severity: MESSAGE_SEVERITY.ERROR,
    })
  } finally {
    reset(formId)
  }
}

defineOptions({
  name: 'SubscriptionsCheckVisitsForm',
})
</script>

<i18n>
de:
  labels:
    subscriptionNumber: "Abonummer"
  btn:
    submit: "Abo suchen"
  messages:
    error: "Abo nicht gefunden."
es:
  labels:
    subscriptionNumber: "Número de suscripción"
  btn:
    submit: "Buscar suscripción"
  messages:
    error: "Suscripción no encontrada."
en:
  labels:
    subscriptionNumber: "Subscription number"
  btn:
    submit: "Search subscription"
  messages:
    error: "Subscription could not be found."
</i18n>
