<script setup lang="ts">
import { useActiveElement } from '@vueuse/core'
import { BUTTON_SIZE, BUTTON_THEME } from '@kh-ui/constants/button'

export interface CarouselClasses {
  buttonPrev?: string | object | []
  buttonNext?: string | object | []
  slide?: string | object | []
  wrapper?: string | object | []
  container?: string | object | []
}

export interface Props {
  slides: any[]
  perPage: number
  imageSelector?: string
  activeSlide?: number
  classes?: CarouselClasses
  buttonTheme?: ButtonThemes
  buttonSize?: ButtonSizes
  hideInactiveButtons?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  imageSelector: '[data-image]',
  classes: () => ({}),
  buttonTheme: BUTTON_THEME.PRIMARY,
  buttonSize: BUTTON_SIZE.SM,
  hideInactiveButtons: true,
  activeSlide: 0,
})

const cssClasses = computed(() => {
  const { slide, buttonNext, buttonPrev, wrapper, container } = props.classes

  return {
    container:
      container ??
      'group relative px-3 sm:px-6 xl:px-8 2xl:px-8 overflow-hidden',
    wrapper: wrapper ?? 'w-full md:overflow-hidden',
    buttonPrev:
      buttonPrev ??
      'hidden sm:flex absolute left-0 md:left-3 xl:left-5 z-20 -translate-y-1/2 data-[is-active="false"]:opacity-25 data-[is-active="false"]:pointer-events-none',
    buttonNext:
      buttonNext ??
      'hidden sm:flex absolute right-0 md:right-3 xl:right-5 z-20 -translate-y-1/2 data-[is-active="false"]:opacity-25 data-[is-active="false"]:pointer-events-none',
    slide:
      slide ??
      'flex flex-col grow-0 shrink-0 overflow-hidden px-1.5 sm:px-2 basis-1/3 sm:basis-1/4 md:basis-1/5 lg:basis-1/6',
  }
})

const { icons } = useDesign()
const activeElement = useActiveElement()
const currentSlide = ref(0)
const slidesVisible = computed(() => props.perPage)
const slidesTotal = computed(() => props.slides.length - 1)

const {
  getWrapperRef,
  position,
  canSlideLeft,
  canSlideRight,
  isAnimating,
  onTouchEnd,
  onTouchMove,
  onTouchStart,
  slideLeft,
  slideRight,
  slideTo,
  sliderWrapperRef,
} = useCarousel({
  slidesVisible,
  slides: {
    preload: 2,
    total: slidesTotal,
  },
  preventDefault: false,
})

if (props.activeSlide > 0) {
  slideTo(props.activeSlide, false)
}

const slideRef = ref()
const imageHeight = ref(0)

function getImageHeight() {
  const image = slideRef.value?.[0]?.querySelector(props.imageSelector)

  if (image) {
    imageHeight.value = image.clientHeight
  }
}

onMounted(() => getImageHeight)
useResizeObserver(sliderWrapperRef, getImageHeight)

defineOptions({
  name: 'HorizontalMediaScroller',
})
</script>

<template>
  <div :class="cssClasses.container">
    <div class="flex h-full w-full">
      <div
        :ref="getWrapperRef as unknown as Ref<HTMLElement>"
        :class="cssClasses.wrapper"
      >
        <ClientOnly>
          <KButton
            v-show="!hideInactiveButtons || canSlideLeft"
            :data-is-active="canSlideLeft"
            :icon="icons.CHEVRON_LEFT"
            :theme="buttonTheme"
            :size="buttonSize"
            :class="cssClasses.buttonPrev"
            :style="`top: ${imageHeight / 2}px`"
            @click="slideLeft(), activeElement?.blur()"
          />
        </ClientOnly>

        <div
          v-if="slides.length"
          class="flex flex-nowrap"
          :class="{
            'transition-transform duration-500 ease-in-out': isAnimating,
          }"
          :style="`transform: translateX(${position}px)`"
          @touchstart.passive="onTouchStart($event)"
          @touchmove.passive="onTouchMove"
          @touchend.passive="onTouchEnd"
        >
          <div
            v-for="(slide, index) in slides"
            :key="index"
            :class="cssClasses.slide"
          >
            <div ref="slideRef" class="contents">
              <slot
                :slide="slide"
                :current-slide="currentSlide"
                :index="index"
              />
            </div>
          </div>
        </div>

        <ClientOnly>
          <KButton
            v-show="!hideInactiveButtons || canSlideRight"
            :data-is-active="canSlideRight"
            :icon="icons.CHEVRON_RIGHT"
            :theme="buttonTheme"
            :size="buttonSize"
            :class="cssClasses.buttonNext"
            :style="`top: ${imageHeight / 2}px`"
            @click="slideRight(), activeElement?.blur()"
          />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
