<template>
  <div>
    <CinemaBanner :cinema="cinema" page="SHOW" class="mb-8" />
    <div id="kinoheld-eframe" />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const appConfig = useAppConfig()
const { cinema } = defineProps<Props>()
const route = useRoute()
const { t } = useI18n()

const { show, error } = await useShow({
  cinemaId: cinema.id,
  urlSlug: route.params.showSlug as string,
})

if (error.value || !show.value) {
  const { redirectWithError } = useRedirectError()

  await redirectWithError({
    message: error.value || REDIRECT_ERROR_STRINGS.NO_SHOW,
    redirect: {
      name: ROUTE_NAMES.CINEMA_PROGRAM,
      params: {
        citySlug: cinema.city.urlSlug,
        cinemaSlug: cinema.urlSlug,
      },
    },
    cinemaId: cinema.id,
  })
}

// Move all the functionality directly to this page
usePageMetas({
  title: `${cinema.name} | ${t('tickets')}`,
  description: t('description', {
    showName: show.value?.name,
    cinemaName: cinema.name,
  }),
  ...show.value?.meta,
})

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: appConfig.legacyShowWidgetUrl
    .replace('{cityUrlSlug}', cinema.city.urlSlug)
    .replace('{cinemaUrlSlug}', cinema.urlSlug)
    .replace('{showUrlSlug}', show.value?.urlSlug!),
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]/vorstellung/[showSlug]',
    es: '/cine/[citySlug]/[cinemaSlug]/evento/[showSlug]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugShowShowSlug',
})
</script>

<i18n>
de:
  tickets: "Kinotickets"
  description: "Jetzt bei kinoheld Tickets für den Film {showName} im Kino {cinemaName} schnell und bequem online kaufen."
es:
  tickets: "Cartelera y horarios"
  description: "Compra ahora en entradas para la película {showName} en el cine {cinemaName} de forma rápida y sencilla."
</i18n>
