<script setup lang="ts">
import type { City } from '#gql/default'
import { ROUTE_NAMES } from '@portal/constants/routes'

interface Props {
  city: City
}

const props = defineProps<Props>()
const route = useRoute()
const getRouteBaseName = useRouteBaseName()
const { isWidgetMode } = useWidgetMode()

const { cinema, pending } = await useCinema({
  variables: {
    urlSlug: route.params.cinemaSlug as string,
    cityUrlSlug: props.city.urlSlug as string,
    design: route.query.design as string | undefined,
  },
  throwError: true,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]',
    es: '/cine/[citySlug]/[cinemaSlug]',
  },
})

const isSlideInVisible = computed(() => {
  return (
    !isWidgetMode.value &&
    getRouteBaseName(route) !== ROUTE_NAMES.CINEMA_SHOP &&
    getRouteBaseName(route) !== ROUTE_NAMES.CINEMA_VOUCHERS
  )
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlug',
})
</script>

<template>
  <div v-if="!pending && cinema" class="contents">
    <PageHeaderSlideIn
      v-if="isSlideInVisible"
      :image="cinema.heroImage!"
      :title="cinema.name"
    />

    <CinemaHead v-if="!isWidgetMode" :cinema />

    <PageContainer class="!pt-0">
      <PageGrid>
        <PageGridColumn v-if="!isWidgetMode">
          <CinemaNavigation :cinema />
        </PageGridColumn>

        <PageGridColumn class="py-8 widget:pt-0">
          <NuxtPage :cinema />
        </PageGridColumn>
      </PageGrid>
    </PageContainer>
  </div>
</template>
